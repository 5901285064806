import React from 'react'

import SectionsChooser from '../sections-chooser'

const CasePageContent = props => {
  return (
    <>
      {/* Dynamic sections */}
      <SectionsChooser
        sections={props.caseSections}
        startDate={props.startDate}
        endDate={props.endDate}
        location={props.location}
        relatedEpisodes={props.relatedEpisodes}
      />
    </>
  )
}

export default CasePageContent
